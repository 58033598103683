<template>
  <BaseDialog :dialogVisible.sync="dialog" width="500px" :title="title">
    <template>
      <BaseForm1 :option="option" v-model="form">
      </BaseForm1>
    </template>
    <template #footer>
      <LoadingBtn type="primary" @click="doSubmit"> 确认</LoadingBtn>
      <LoadingBtn @click="cancel"> 取消</LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import { commonFromMixin } from '@/mixins'
import { setDesignSuggestions as add } from '@/api/product/protoSizeApi'
import { charLenLimitConstructor } from '@/utils/validate'

export default {
  mixins: [commonFromMixin],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      option: {
        size: 'small',
        column: [{
          label: '定制器备注',
          prop: 'designSuggestions',
          rules: [{
            validator: charLenLimitConstructor(0, 200)
          }]
        }]
      },
      form: {
        designSuggestions: ''
      }
    }
  },

  methods: {
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      const referSizeId = this.data.referSize.id
      const { code } = await add({
        referSizeId,
        ...this.form
      })
      $SUC({ code }) && this.sup_this && this.sup_this.init()
    }
  }
}
</script>